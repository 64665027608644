import React from "react";
import {Modal, ModalBody, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {useModal} from "../../../redux/ui/hooks";
import {useTranslation} from "../../../helpers/i18nUtils";
import {Form as FormikForm, Formik, FormikHelpers} from "formik";
import {AfvoerMetSifonAanwezig, ProductType} from "../../../redux/installatie/types";
import * as Yup from "yup";
import {array, mixed, number} from "yup";
import {Skill} from "../../../redux/planning/types";
import {Button, Col, Form, InputGroup, ProgressBar, Row} from "react-bootstrap";
import BooleanInstallatieVereiste from "../../installatie/dossierdetail/BooleanInstallatieVereiste";
import ChoiceInstallatieVereiste from "../../installatie/dossierdetail/ChoiceInstallatieVereiste";
import {LoadingButton} from "../../../components/aqualex/LoadingButton";
import {InstantFileUpload} from "../../../components/aqualex/InstantFileUpload";
import {SimpleFormField} from "../../../components/aqualex/form/SimpleFormField";
import IntegerInstallatieVereiste from "../../installatie/dossierdetail/IntegerInstallatieVereiste";
import {UpdateBesteldProductInstallatieInfoForm} from "../../../redux/bestelbon/types";
import {
    useBestelBonLijnInstallatieInfoVerwijderSituatieFoto,
    useBestelBonLijnInstallatieInfoVoegSituatieFotoToe,
    useGetBestelBonById,
    useUpdateBestelBonLijnInstallatieInfo
} from "../../../redux/bestelbon/api";
import {LoadingSkeleton} from "../../../components/aqualex/LoadingSkeleton";
import {SwitchFormField} from "../../../components/aqualex/form/SwitchFormField";
import {HelpTooltip} from "../../../components/aqualex/HelpTooltip";

export interface BestelBonLijnInstallatieInfoIngevenModalProps {
    bestelBonId: string;
    bestelBonLijnId: string;
}

export const BestelBonLijnInstallatieInfoIngevenModal: React.FC<BestelBonLijnInstallatieInfoIngevenModalProps> = (props) => {
    const {bestelBonId, bestelBonLijnId} = props;

    const {t} = useTranslation("bestelbon");
    const {hideModal} = useModal();

    const {data: bestelBon, isLoading} = useGetBestelBonById(bestelBonId);
    const bestelBonLijn = bestelBon?.lijnen?.find(item => item.id === bestelBonLijnId);

    const [updateInstallatieInfo] = useUpdateBestelBonLijnInstallatieInfo();
    const [voegSituatieFotoToe] = useBestelBonLijnInstallatieInfoVoegSituatieFotoToe();
    const [verwijderSituatieFoto] = useBestelBonLijnInstallatieInfoVerwijderSituatieFoto();

    const [index, setIndex] = React.useState<number>(0);
    const [bewarenOpAlleProgress, setBewarenOpAlleProgress] = React.useState(0);

    if (!bestelBonLijn) {
        console.error("Bestelbonlijn niet gevonden, fout in data");
        hideModal();
        return null;
    }

    const installatieInfo = bestelBonLijn.installatieInfo[index];
    const installatieProfiel = installatieInfo.installatieProfiel;

    const schema = Yup.object({
        stopcontactenAanwezig: number().nullable().min(0,
            t("Foutmeldingen.aantal-stopcontacten-kan-niet-negatief-zijn",
                'Aantal stopcontacten kan niet negatief zijn') as string),
        stopcontactenBovenAanwezig: number().nullable().min(0,
            t("Foutmeldingen.aantal-stopcontacten-kan-niet-negatief-zijn",
                'Aantal stopcontacten kan niet negatief zijn') as string),
        skills: array().of(mixed<Skill>().oneOf(Object.values(Skill))).min(1,
            t("Foutmeldingen.er-moet-minstens-1-skill-worden-gekozen",
                "Er moet minstens 1 skill gekozen worden") as string),
    });

    const initialValues: UpdateBesteldProductInstallatieInfoForm = {
        locatieBeschrijving: installatieInfo?.locatieBeschrijving ?? "",
        gebouw: installatieInfo?.gebouw ?? "",
        verdieping: installatieInfo?.verdieping ?? "",
        lokaalNummer: installatieInfo?.lokaalNummer ?? "",

        elektriciteitAanwezig: installatieInfo?.elektriciteitAanwezig ?? false,
        stopcontactenAanwezig: installatieInfo?.stopcontactenAanwezig ?? 0,
        opmerkingBijStopcontacten: installatieInfo?.opmerkingBijStopcontacten ?? "",
        stopcontactenBovenAanwezig: installatieInfo?.stopcontactenBovenAanwezig ?? 0,
        opmerkingBijStopcontactenBoven: installatieInfo?.opmerkingBijStopcontactenBoven ?? "",
        ventilatieAanwezig: installatieInfo?.ventilatieAanwezig ?? false,
        opmerkingBijVentilatie: installatieInfo?.opmerkingBijVentilatie ?? "",

        waterOpDeLeidingAanwezig: installatieInfo?.waterOpDeLeidingAanwezig ?? false,
        dubbeleDienstkraanBijToestelAanwezig: installatieInfo?.dubbeleDienstkraanBijToestelAanwezig ?? false,
        opmerkingBijDubbeleDienstkraanBijToestel: installatieInfo?.opmerkingBijDubbeleDienstkraanBijToestel ?? "",
        schellKraanWarmEnKoudAanwezig: installatieInfo?.schellKraanWarmEnKoudAanwezig ?? false,
        opmerkingBijSchellKraanWarmEnKoud: installatieInfo?.opmerkingBijSchellKraanWarmEnKoud ?? "",
        schellKraanWarmEnKoudBovenAanwezig: installatieInfo?.schellKraanWarmEnKoudBovenAanwezig ?? false,
        opmerkingBijSchellKraanWarmEnKoudBoven: installatieInfo?.opmerkingBijSchellKraanWarmEnKoudBoven ?? "",
        afvoerMetSifonAanwezig: installatieInfo?.afvoerMetSifonAanwezig ?? AfvoerMetSifonAanwezig.GEEN_AFVOER_AANWEZIG,
        opmerkingBijAfvoerMetSifon: installatieInfo?.opmerkingBijAfvoerMetSifon ?? "",
        afvoerMetSifonBovenAanwezig: installatieInfo?.afvoerMetSifonBovenAanwezig ?? AfvoerMetSifonAanwezig.GEEN_AFVOER_AANWEZIG,
        opmerkingBijAfvoerMetSifonBoven: installatieInfo?.opmerkingBijAfvoerMetSifonBoven ?? "",
        boringInWerkbladDoorKlant: installatieInfo?.boringInWerkbladDoorKlant ?? false,
        opmerkingBijBoringInWerkbladDoorKlant: installatieInfo?.opmerkingBijBoringInWerkbladDoorKlant ?? "",
        wachtbuis40mmAanwezig: installatieInfo?.wachtbuis40mmAanwezig ?? false,
        opmerkingBijWachtbuis40mm: installatieInfo?.opmerkingBijWachtbuis40mm ?? "",
        installatieInReedsAanwezigeKeukenOfKast: installatieInfo?.installatieInReedsAanwezigeKeukenOfKast ?? false,
        opmerkingBijInstallatieInReedsAanwezigeKeukenOfKast: installatieInfo?.opmerkingBijInstallatieInReedsAanwezigeKeukenOfKast ?? "",

        algemeneOpmerking: installatieInfo?.algemeneOpmerking ?? "",

        index: installatieInfo?.index
    };

    const onSubmit = async (values: UpdateBesteldProductInstallatieInfoForm, helpers: FormikHelpers<any>) => {
        try {
            await updateInstallatieInfo({...values, bestelBonId, bestelBonLijnId}).unwrap();

            if (index + 1 >= bestelBonLijn.besteldAantal) {
                hideModal();
            }
        } finally {
            helpers.setSubmitting(false);
        }
    };

    const onBewarenOpAlleClick = async (values: UpdateBesteldProductInstallatieInfoForm, helpers: FormikHelpers<any>) => {
        try {
            for (let i = 0; i < bestelBonLijn.besteldAantal; i++) {
                helpers.setSubmitting(true);

                const isLastItem = i === bestelBonLijn.besteldAantal - 1;
                await updateInstallatieInfo({
                    ...values,
                    bestelBonId,
                    bestelBonLijnId,
                    index: i,
                    skipInvalidation: !isLastItem
                }).unwrap();

                setBewarenOpAlleProgress((i + 1) / bestelBonLijn.besteldAantal);
            }

            hideModal();
        } finally {
            setBewarenOpAlleProgress(0);
            helpers.setSubmitting(false);
        }
    };

    const situatieFotoToevoegen = async (bestand: File) => {
        await voegSituatieFotoToe({bestelBonId, bestelBonLijnId, index, situatieFoto: bestand});
    };

    const situatieFotoVerwijderen = async (bestandId: string) => {
        await verwijderSituatieFoto({bestelBonId, bestelBonLijnId, index, bestandId});
    };

    const renderNavigation = (dirty: boolean) => {
        if (bestelBonLijn.besteldAantal > 1) {
            const onNextClick = () => {
                if (!dirty) {
                    setIndex(index + 1);
                }
            };

            const onPreviousClick = () => {
                if (!dirty) {
                    setIndex(index - 1);
                }
            };

            return (
                <div className="d-flex flex-column align-items-end">
                    <div className="d-flex align-items-center justify-content-around" style={{width: 200}}>
                        <HelpTooltip id="installatie-info-previous" enabled={dirty && index !== 0} content={
                            <Button variant="link" onClick={onPreviousClick} disabled={index === 0}>
                                <i className="dripicons-chevron-left"/>
                            </Button>
                        }>
                            {t("Labels.je-moet-het-formulier-eerst-bewaren", "Je moet het formulier eerst bewaren")}
                        </HelpTooltip>

                        {(index + 1)} / {bestelBonLijn.besteldAantal}

                        <HelpTooltip id="installatie-info-previous"
                                     enabled={dirty && index !== bestelBonLijn.besteldAantal - 1} content={
                            <Button variant="link" onClick={onNextClick}
                                    disabled={index === bestelBonLijn.besteldAantal - 1}>
                                <i className="dripicons-chevron-right"/>
                            </Button>
                        }>
                            {t("Labels.je-moet-het-formulier-eerst-bewaren", "Je moet het formulier eerst bewaren")}
                        </HelpTooltip>
                    </div>
                </div>
            );
        }

        return null;
    };

    return (
        <Modal show={true} onHide={hideModal} size="xl" autoFocus>
            <LoadingSkeleton loading={isLoading}>
                {() =>
                    <Formik<UpdateBesteldProductInstallatieInfoForm> validationSchema={schema}
                                                                     onSubmit={onSubmit}
                                                                     enableReinitialize
                                                                     validateOnBlur={false}
                                                                     validateOnChange={false}
                                                                     initialValues={initialValues}
                    >
                        {(formikProps) => {
                            const {
                                isSubmitting,
                                dirty,
                                submitForm,
                                values
                            } = formikProps;

                            return (
                                <>
                                    <ModalHeader closeButton={true}>
                                        <Row className="w-100 justify-content-end">
                                            <Col>
                                                <ModalTitle>{t("Titels.installatieinfo", "Installatieinfo")}
                                                    <span className="text-muted"> {installatieProfiel?.code}</span> </ModalTitle>
                                            </Col>

                                            <Col xs="auto">
                                                {renderNavigation(dirty)}
                                            </Col>
                                        </Row>
                                    </ModalHeader>

                                    <ModalBody>
                                        <FormikForm>
                                            <Row>
                                                <Col>
                                                    <Form.Group as={Form.Row}>
                                                        <Form.Label column
                                                                    sm={2}>{t("Labels.locatie", "Locatie")}</Form.Label>
                                                        <Col sm={10}>
                                                            <InputGroup className="mb-2" hasValidation>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text><i
                                                                        className='mdi mdi-map-marker'/></InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <SimpleFormField name="locatieBeschrijving"
                                                                                 placeholder={t("Placeholders.toestel-locatie", "Toestel Locatie")}
                                                                                 showFeedback={false}/>
                                                                <SimpleFormField name="gebouw"
                                                                                 placeholder={t("Placeholders.gebouw", "Gebouw")}
                                                                                 showFeedback={false}/>
                                                                <SimpleFormField name="verdieping"
                                                                                 placeholder={t("Placeholders.verdieping", "Verdieping")}
                                                                                 showFeedback={false}/>
                                                                <SimpleFormField name="lokaalNummer"
                                                                                 placeholder={t("Placeholders.lokaal", "Lokaal")}
                                                                                 showFeedback={false}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Form.Row}>
                                                        <Form.Label column
                                                                    sm={12}>{t("Labels.algemeneOpmerking", "Algemene opmerking")}</Form.Label>
                                                        <Col sm={12}>
                                                            <SimpleFormField name="algemeneOpmerking"
                                                                             showFeedback={false}/>
                                                        </Col>
                                                    </Form.Group>

                                                    <BooleanInstallatieVereiste
                                                        guard={installatieProfiel.installatieInReedsAanwezigeKeukenOfKast}
                                                        label={t("Labels.wordt-geïnstalleerd-in-keuken-of-kast-die-reeds-aanwezig-is",
                                                            'Wordt geïnstalleerd in keuken of kast die reeds aanwezig is')}
                                                        waardePropertyName='installatieInReedsAanwezigeKeukenOfKast'
                                                        waarde={installatieInfo?.installatieInReedsAanwezigeKeukenOfKast}
                                                        opmerkingPropertyName='opmerkingBijInstallatieInReedsAanwezigeKeukenOfKast'
                                                        opmerking={installatieInfo?.opmerkingBijInstallatieInReedsAanwezigeKeukenOfKast}/>

                                                    <BooleanInstallatieVereiste
                                                        guard={installatieProfiel.boringInWerkbladNodig}
                                                        label={t("Labels.boring-in-werkblad-door-klant", 'Boring in werkblad door klant')}
                                                        waardePropertyName='boringInWerkbladDoorKlant'
                                                        waarde={installatieInfo?.boringInWerkbladDoorKlant}
                                                        opmerkingPropertyName='opmerkingBijBoringInWerkbladDoorKlant'
                                                        opmerking={installatieInfo?.opmerkingBijBoringInWerkbladDoorKlant}/>

                                                    <Row>
                                                        <Col className="mr-4">
                                                            {installatieProfiel.productType !== ProductType.ANDERE && (
                                                                <Form.Group as={Form.Row}
                                                                            className="align-items-center">
                                                                    <Col sm="auto">
                                                                        <SwitchFormField
                                                                            name="elektriciteitAanwezig"/>
                                                                    </Col>
                                                                    <Form.Label column>
                                                                        {t("Labels.er-is-elektriciteit-aanwezig", "Er is elektriciteit aanwezig")}
                                                                    </Form.Label>
                                                                </Form.Group>
                                                            )}

                                                            <IntegerInstallatieVereiste
                                                                guard={installatieProfiel.stopcontacten > 0}
                                                                label={t("Labels.stopcontacten-x-nodig",
                                                                    `Stopcontacten ({{aantal}} nodig)`,
                                                                    {aantal: installatieProfiel.stopcontacten})}
                                                                waardePropertyName='stopcontactenAanwezig'
                                                                waarde={installatieInfo?.stopcontactenAanwezig}
                                                                opmerkingPropertyName='opmerkingBijStopcontacten'
                                                                opmerking={installatieInfo?.opmerkingBijStopcontacten}/>
                                                            <IntegerInstallatieVereiste
                                                                guard={installatieProfiel.stopcontactenBoven > 0}
                                                                label={t("Labels.stopcontacten-boven-x-nodig",
                                                                    `Stopcontacten boven ({{aantal}} nodig)`,
                                                                    {aantal: installatieProfiel.stopcontactenBoven})}
                                                                waardePropertyName='stopcontactenBovenAanwezig'
                                                                waarde={installatieInfo?.stopcontactenBovenAanwezig}
                                                                opmerkingPropertyName='opmerkingBijStopcontactenBoven'
                                                                opmerking={installatieInfo?.opmerkingBijStopcontactenBoven}/>

                                                            <BooleanInstallatieVereiste
                                                                guard={installatieProfiel.ventilatie}
                                                                label={t("Labels.ventilatie", 'Ventilatie')}
                                                                waardePropertyName='ventilatieAanwezig'
                                                                waarde={installatieInfo?.ventilatieAanwezig}
                                                                opmerkingPropertyName='opmerkingBijVentilatie'
                                                                opmerking={installatieInfo?.opmerkingBijVentilatie}/>
                                                        </Col>

                                                        <Col className="ml-4">
                                                            {installatieProfiel.productType !== ProductType.ANDERE && (
                                                                <Form.Group as={Form.Row}
                                                                            className="align-items-center">
                                                                    <Col sm="auto">
                                                                        <SwitchFormField
                                                                            name="waterOpDeLeidingAanwezig"/>
                                                                    </Col>
                                                                    <Form.Label column>
                                                                        {t("Labels.er-is-water-op-de-leiding-aanwezig", "Er is water op de leiding aanwezig")}
                                                                    </Form.Label>
                                                                </Form.Group>
                                                            )}

                                                            <BooleanInstallatieVereiste
                                                                guard={installatieProfiel.dubbeleDienstkraanBijToestel}
                                                                label={t("Labels.dubbele-dienstkraan-bij-toestel", 'Dubbele dienstkraan bij toestel')}
                                                                waardePropertyName='dubbeleDienstkraanBijToestelAanwezig'
                                                                waarde={installatieInfo?.dubbeleDienstkraanBijToestelAanwezig}
                                                                opmerkingPropertyName='opmerkingBijDubbeleDienstkraanBijToestel'
                                                                opmerking={installatieInfo?.opmerkingBijDubbeleDienstkraanBijToestel}/>
                                                            <BooleanInstallatieVereiste
                                                                guard={installatieProfiel.schellKraanWarmEnKoud}
                                                                label={t("Labels.schell-kraan-warm-en-koud",
                                                                    'Schell kraan warm & koud')}
                                                                waardePropertyName='schellKraanWarmEnKoudAanwezig'
                                                                waarde={installatieInfo?.schellKraanWarmEnKoudAanwezig}
                                                                opmerkingPropertyName='opmerkingBijSchellKraanWarmEnKoud'
                                                                opmerking={installatieInfo?.opmerkingBijSchellKraanWarmEnKoud}/>
                                                            <BooleanInstallatieVereiste
                                                                guard={installatieProfiel.schellKraanWarmEnKoudBoven}
                                                                label={t("Labels.schell-kraan-warm-en-koud-boven",
                                                                    'Schell kraan warm & koud boven')}
                                                                waardePropertyName='schellKraanWarmEnKoudBovenAanwezig'
                                                                waarde={installatieInfo?.schellKraanWarmEnKoudBovenAanwezig}
                                                                opmerkingPropertyName='opmerkingBijSchellKraanWarmEnKoudBoven'
                                                                opmerking={installatieInfo?.opmerkingBijSchellKraanWarmEnKoudBoven}/>

                                                            <ChoiceInstallatieVereiste
                                                                guard={installatieProfiel.afvoerMetSifon}
                                                                label={t("Labels.afvoer", 'Afvoer')}
                                                                mogelijkeWaarden={[
                                                                    {
                                                                        value: AfvoerMetSifonAanwezig.GEEN_AFVOER_AANWEZIG,
                                                                        label: t("Labels.geen-afvoer-aanwezig", 'Geen afvoer aanwezig')
                                                                    },
                                                                    {
                                                                        value: AfvoerMetSifonAanwezig.AFVOER_AANWEZIG,
                                                                        label: t("Labels.enkel-afvoer-aanwezig", 'Enkel afvoer aanwezig')
                                                                    },
                                                                    {
                                                                        value: AfvoerMetSifonAanwezig.AFVOER_EN_SIFON_AANWEZIG,
                                                                        label: t("Labels.afvoer-met-sifon-aanwezig", 'Afvoer met sifon aanwezig')
                                                                    }]}
                                                                waardePropertyName='afvoerMetSifonAanwezig'
                                                                waarde={installatieInfo?.afvoerMetSifonAanwezig}
                                                                waardeColSize={4}
                                                                opmerkingPropertyName='opmerkingBijAfvoerMetSifon'
                                                                opmerking={installatieInfo?.opmerkingBijAfvoerMetSifon}/>
                                                            <ChoiceInstallatieVereiste
                                                                guard={installatieProfiel.afvoerMetSifonBoven}
                                                                label={t("Labels.afvoer-boven", 'Afvoer boven')}
                                                                mogelijkeWaarden={[
                                                                    {
                                                                        value: AfvoerMetSifonAanwezig.GEEN_AFVOER_AANWEZIG,
                                                                        label: t("Labels.geen-afvoer-aanwezig", 'Geen afvoer aanwezig')
                                                                    },
                                                                    {
                                                                        value: AfvoerMetSifonAanwezig.AFVOER_AANWEZIG,
                                                                        label: t("Labels.enkel-afvoer-aanwezig", 'Enkel afvoer aanwezig')
                                                                    },
                                                                    {
                                                                        value: AfvoerMetSifonAanwezig.AFVOER_EN_SIFON_AANWEZIG,
                                                                        label: t("Labels.afvoer-met-sifon-aanwezig", 'Afvoer met sifon aanwezig')
                                                                    }]}
                                                                waardePropertyName='afvoerMetSifonBovenAanwezig'
                                                                waarde={installatieInfo?.afvoerMetSifonBovenAanwezig}
                                                                opmerkingPropertyName='opmerkingBijAfvoerMetSifonBoven'
                                                                opmerking={installatieInfo?.opmerkingBijAfvoerMetSifonBoven}/>

                                                            <BooleanInstallatieVereiste
                                                                guard={installatieProfiel.wachtbuis40mm}
                                                                label={t("Labels.wachtbuis-40mm", 'Wachtbuis 40mm')}
                                                                waardePropertyName='wachtbuis40mmAanwezig'
                                                                waarde={installatieInfo?.wachtbuis40mmAanwezig}
                                                                opmerkingPropertyName='opmerkingBijWachtbuis40mm'
                                                                opmerking={installatieInfo?.opmerkingBijWachtbuis40mm}/>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>


                                            <Row className="mb-2">
                                                {installatieInfo?.situatieFotos?.map(foto => (
                                                    <Col xs={2} className="text-right">
                                                        <a href={foto.previewUri} target="_blank"
                                                           rel="noopener noreferrer"
                                                           style={{
                                                               display: "block",
                                                               position: "relative",
                                                               width: "100%",
                                                               height: 0,
                                                               paddingBottom: "100%"
                                                           }}>
                                                            <img src={foto.downloadUri}
                                                                 alt={foto.naam} style={{
                                                                position: "absolute",
                                                                left: 0,
                                                                right: 0,
                                                                bottom: 0,
                                                                top: 0,
                                                                width: "100%",
                                                                height: "100%",
                                                                objectFit: "cover"
                                                            }}/>
                                                        </a>
                                                        <LoadingButton variant="link"
                                                                       onClick={() => {
                                                                           situatieFotoVerwijderen(foto.id);
                                                                       }}
                                                                       loading={false}>
                                                            <i className="mdi mdi-trash-can"/>
                                                        </LoadingButton>
                                                    </Col>
                                                ))}

                                                <Col xs={installatieInfo?.situatieFotos?.length > 0 ? 2 : 12} className="pb-1">
                                                    <InstantFileUpload initialValues={[]}
                                                                       onUpload={async (bestand) => {
                                                                           await situatieFotoToevoegen(bestand);
                                                                       }}
                                                                       renderFiles={false}
                                                                       dropzoneClassName="dropzone--small"/>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <div className="d-flex justify-content-end">
                                                        <Button variant="light" onClick={() => hideModal()}>
                                                            {t("algemeen:Buttons.annuleren", "Annuleren")}
                                                        </Button>

                                                        {index === 0 && bestelBonLijn.besteldAantal > 1 && (
                                                            <LoadingButton variant="primary" loading={isSubmitting}
                                                                           type="button" className="ml-2"
                                                                           onClick={() => onBewarenOpAlleClick(values, formikProps)}>
                                                                {t("algemeen:Buttons.bewaren-op-alle", "Bewaren op alle")}
                                                            </LoadingButton>
                                                        )}

                                                        <LoadingButton variant="primary" loading={isSubmitting}
                                                                       type="submit" className="ml-2">
                                                            {t("algemeen:Buttons.bewaren", "Bewaren")}
                                                        </LoadingButton>

                                                        {bestelBonLijn.besteldAantal > 1 && index < bestelBonLijn.besteldAantal - 1 && (
                                                            <LoadingButton onClick={submitForm} variant="primary"
                                                                           loading={isSubmitting} loaded={() => {
                                                                setIndex(Math.min(bestelBonLijn.besteldAantal, index + 1));
                                                                const dialog = document.querySelector("div[role=dialog]");
                                                                if (dialog) {
                                                                    dialog.scrollTop = 0;
                                                                }
                                                            }}
                                                                           type="submit" className="ml-2">
                                                                {t("algemeen:Buttons.bewaren-en-volgende", "Bewaren en volgende")}
                                                            </LoadingButton>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            {bewarenOpAlleProgress > 0 && (
                                                <Row className="mt-3">
                                                    <Col>
                                                        {t("Labels.aan-het-bewaren-op-alle-dot-dot-dot", "Aan het bewaren op alle...")}
                                                        <ProgressBar now={bewarenOpAlleProgress * 100}/>
                                                    </Col>
                                                </Row>
                                            )}
                                        </FormikForm>
                                    </ModalBody>
                                </>
                            );
                        }}
                    </Formik>}
            </LoadingSkeleton>
        </Modal>
    )
};
