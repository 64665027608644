import { ApiError } from '../ui/types';
import { EntityDict } from '../support/pagination';
import { KlantModel } from '../klant/types';
import { VerzendAdresModel } from '../verzendadres/types';
import { MedewerkerModel } from '../medewerker/types';
import { BestandModel, VerkoopOrderRefModel } from '../installatie/types';
import { ArtikelModel, Co2Kenmerken } from '../toestel/types';
import { TransportKostConfiguratieModel, TransportKostConfiguratieType } from '../types';
import { OpdrachtPlanningModel, PrestatieModel } from '../planning/types';
import { KlantAccountModel } from '../klantaccount/types';

export type Co2BestellingState = {
    entities: {
        co2Bestelling: EntityDict<Co2BestellingModel>;
    };
    klantId2co2ArtikelId: EntityDict<string>;
    verzendAdresId2co2ArtikelId: EntityDict<string>;
    error?: ApiError;
};

export type Co2BestellingFilters = {
    klantNaam?: string;
    klantNr?: string;
    klantDivisie?: string;
    status?: Co2BestellingStatus[];
    statusNotIn?: Co2BestellingStatus[];
    geplandeDatum?: Date;
};

export interface UpdateCo2BestellingForm {
    klantReferentieBijlage?: File;
}

export type NieuweCo2BestellingForm = {
    klantId: string;
    verzendAdresId: string;
    besteldArtikelId: string;
    aantal: number;
    extraAantal?: number;
    klantReferentieNummer?: string;
    klantReferentieBijlage?: File;

    emailAdressen: string[];

    aangevraagdeLevertermijn: string;
    extraAanvraagInfo?: string;

    transportKostType: TransportKostConfiguratieType;
    transportKostValue?: number;

    extraCo2FlesBeleid: ExtraCo2FlesBeleid;
};

export type AnnuleerCo2BestellingForm = {
    reden: string;
};

export enum Co2BestellingStatus {
    NIEUW = "NIEUW",
    GEANNULEERD = "GEANNULEERD",
    PLANNING = "PLANNING",
    VERZENDEN_MET_KOERIER = "VERZENDEN_MET_KOERIER",
    VERZONDEN_MET_KOERIER = "VERZONDEN_MET_KOERIER",
    GELEVERD = "GELEVERD",
    WACHT_OP_UITVOERING = "WACHT_OP_UITVOERING",
    NIET_UITGEVOERD = "NIET_UITGEVOERD"
}

export enum OorsprongType {
    MY_AQUALEX = 'MY_AQUALEX',
    BACK_OFFICE = 'BACK_OFFICE',
    TER_PLAATSE = "TER_PLAATSE",
    NALEVERING = "NALEVERING"
}

export interface Co2BestellingModel {
    id: string;
    nummer: number;
    status: Co2BestellingStatus;
    creatieTijdstip: string;
    oorsprongType: OorsprongType;
    aangemaaktDoor: {
        klantAccount?: KlantAccountModel;
        medewerker?: MedewerkerModel;
    };
    extraAanvraagInfo: string;
    klantReferentieNummer: string;
    klantReferentieBijlage: BestandModel;
    klant: KlantModel;
    verzendAdres: VerzendAdresModel;
    besteldArtikel: Co2ArtikelModel;
    aantal: number;
    extraAantal: number;
    opmerking: string;
    aangevraagdeLevertermijn: string;
    levertermijnBijLevering: string;
    annulatieMedewerker?: MedewerkerModel;
    annulatieTijdstip?: string;
    annulatieReden?: string;
    opdrachtId?: string;
    prestaties?: PrestatieModel[];
    verkoopOrder?: VerkoopOrderRefModel;
    geleverdAantal?: number;
    geleverdExtraAantal?: number;
    geleverdTijdstip?: string;
    geleverdDoorMedewerker?: MedewerkerModel;
    verzondenTijdstip?: string;
    verzondenDoorMedewerker?: MedewerkerModel;
    transportKostConfiguratie: TransportKostConfiguratieModel;
    emailAdressen: String[];
    extraCo2FlesBeleid: ExtraCo2FlesBeleid;
    opdrachtPlanning?: OpdrachtPlanningModel;
}

export type Co2ArtikelModel = ArtikelModel & {
    co2Kenmerken: Co2Kenmerken;
    inGebruik?: boolean;
};

export interface SearchCo2ArtikelenForm {
    verzendAdresId?: string;
}

export enum ExtraCo2FlesBeleid {
    INHERIT = "INHERIT",
    GEBRUIKSRECHT = "GEBRUIKSRECHT",
    WAARBORG = "WAARBORG",
    GEEN = "GEEN"
}
