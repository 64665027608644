import {
    BestelBonFilters,
    BestelBonLijnInstallatieInfoModel,
    BestelBonModel,
    UpdateBesteldProductInstallatieInfoForm,
    UpdateInstallatieInfoModel
} from "./types";
import {
    buildApi,
    buildFilteredAndPagedSearchQuery,
    buildGetByIdQuery,
    buildMutation,
    buildUpdateMutation
} from "../support/ApiBuilderUtils";
import {installTranslationKey} from "../../helpers/i18nUtils";

export const bestelBonApi = buildApi(build => ({
    getBestelBonById: buildGetByIdQuery<BestelBonModel, string>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.ophalen-bestelbon", "ophalen bestelbon"), "BestelBon", id => `/bestelbon/${id}`),
    searchBestelBon: buildFilteredAndPagedSearchQuery<BestelBonModel, BestelBonFilters>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.ophalen-bestelbonoverzicht", "ophalen bestelbonoverzicht"), "BestelBon", () => "/bestelbon"),
    bestelBonAccepteerHandtekening: buildUpdateMutation<void, { id: string }>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.accepteren-handtekening-van-bestelbon", "accepteren handtekening van bestelbon"), "BestelBon", ({id}) => `/bestelbon/${id}/aanvaardHandtekening`),
    annuleerBestelBon: buildUpdateMutation<void, { id: string; reden: string; toelichting: string }>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.annuleren-van-bestelbon", "annuleren van bestelbon"), "BestelBon", ({id}) => `/bestelbon/${id}/annulatie`, {}, {useParams: true}),
    bevestigBestelBon: buildUpdateMutation<void, { id: string } & UpdateInstallatieInfoModel>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.bevestigen-van-bestelbon", "bevestigen van bestelbon"), "BestelBon", ({id}) => `/bestelbon/${id}/bevestig`),
    koppelBestelBonAanKlant: buildUpdateMutation<void, { id: string; klantId: string }>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.koppelen-van-bestelbon-aan-klant", "koppelen van bestelbon aan klant"), "BestelBon", ({id}) => `/bestelbon/${id}/koppelAanKlant`, {}, {useParams: true}),
    setFacturatieKlant: buildUpdateMutation<void, { id: string; klantId: string }>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.instellen-van-facturatie-klant-op-bestelbon", "instellen van facturatieklant op bestelbon"), "BestelBon", ({id}) => `/bestelbon/${id}/facturatieKlant`, {}, {useParams: true}),
    verwijderFacturatieKlant: buildUpdateMutation<void, { id: string }>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.verwijderen-van-facturatie-klant-op-bestelbon", "verwijderen van facturatieklant op bestelbon"), "BestelBon", ({id}) => `/bestelbon/${id}/facturatieKlant`, {}, {method:"DELETE"}),
    koppelBestelBonVerzendAdressen: buildUpdateMutation<void, { id: string } & Record<string, string>>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.koppelen-van-bestelbon-aan-verzendadres", "koppelen van bestelbon aan verzendadres"), "BestelBon", ({id}) => `/bestelbon/${id}/koppelVerzendAdressen`),
    bestelBonLaadBewijsstukPdf: buildUpdateMutation<void, { id: string }>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.pdf-bewijsstuk-laden", "pdf bewijsstuk laden"), "BestelBon", ({id}) => `/bestelbon/${id}/laadBewijsstukPdf`),
    bestelBonNieuwContract: buildMutation<void, FormData>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.nieuw-contract-toevoegen", "nieuw contract toevoegen"), "BestelBon", (formData) => `/bestelbon/${formData.get("id")}/contract`),
    tekenBestelBon: buildMutation<void, FormData>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.bestelbon-tekenen", "bestelbon tekenen"), "BestelBon", (formData) => `/bestelbon/${formData.get("id")}/tekenManueel`),
    verwerkBestelBon: buildUpdateMutation<void, { id: string }>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.bestelbon-verwerken", "bestelbon verwerken"), "BestelBon", ({id}) => `/bestelbon/${id}/verwerk`),
    bestelBonVerwijderContract: buildUpdateMutation<void, { id: string; contractId: string }>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.contract-verwijderen", "contract verwijderen"), "BestelBon", ({
                                                                                                                            id,
                                                                                                                            contractId
                                                                                                                        }) => `/bestelbon/${id}/contract/${contractId}`, {}, {method: "DELETE"}),
    bestelBonWeigerHandtekening: buildUpdateMutation<void, { id: string }>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.weigeren-van-handtekening", "weigeren van handtekening"), "BestelBon", ({id}) => `/bestelbon/${id}/weigerHandtekening`),
    bestelBonWijzigCommissieVerdeling: buildUpdateMutation<void, {
        id: string;
        commissieVerdeling: { medewerkerId: string; percentage: number; }[]
    }>(build, installTranslationKey("bestelbon:APIFoutmeldingen.wijzigen-van-commissieverdeling", "wijzigen van commissieverdeling"), "BestelBon", ({id}) => (`/bestelbon/${id}/commissieVerdeling`)),
    updateBestelBon: buildUpdateMutation<void, { id: string; klantReferentieNummer: string; }>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.bestelbon-wijzigen", "bestelbon wijzigen"), "BestelBon", ({id}) => `/bestelbon/${id}`, {}, {method: "PATCH"}),

    updateBestelBonLijnInstallatinfo: buildMutation<BestelBonLijnInstallatieInfoModel, {
        bestelBonId: string;
        bestelBonLijnId: string;
        index: number;
        skipInvalidation?: boolean;
    } & UpdateBesteldProductInstallatieInfoForm>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.installatie-info-bijwerken", "installatieinfo bijwerken"),
        "BestelBon",
        ({
             bestelBonId,
             bestelBonLijnId,
             index
         }) => `/bestelbon/${bestelBonId}/besteldProduct/${bestelBonLijnId}/${index}/installatie-info`,
        {
            skipInvalidation: (result, error, arg, meta) => arg.skipInvalidation
        },
        {method: "PATCH"}
    ),
    voegBestelBonLijnInstallatinfoSituatieFotoToe: buildMutation<BestelBonLijnInstallatieInfoModel, {
        bestelBonId: string;
        bestelBonLijnId: string;
        index: number;
        situatieFoto: File
    }>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.situatiefoto-toevoegen", "situatie toevoegen"),
        "BestelBon",
        ({
             bestelBonId,
             bestelBonLijnId,
             index
         }) => `/bestelbon/${bestelBonId}/besteldProduct/${bestelBonLijnId}/${index}/situatieFoto`,
        {}, {},
        form => {
            const formData = new FormData();
            Object.keys(form).forEach(key => formData.set(key, form[key]));
            formData.set("situatieFoto", form.situatieFoto, form.situatieFoto.name);

            return formData;
        }
    ),
    verwijderBestelBonLijnInstallatinfoSituatieFoto: buildMutation<BestelBonLijnInstallatieInfoModel, {
        bestelBonId: string;
        bestelBonLijnId: string;
        index: number;
        bestandId: string
    }>(build, installTranslationKey("bestelbon:APIFoutmeldingen.situatiefoto-verwijderen", "situatiefoto-verwijderen"), "BestelBon",
        ({
             bestelBonId,
             bestelBonLijnId,
             index,
             bestandId
         }) => `/bestelbon/${bestelBonId}/besteldProduct/${bestelBonLijnId}/${index}/situatieFoto/${bestandId}`, {}, {method: "DELETE"}),

    updateBestelBonVerkoper: buildUpdateMutation<void, { id: string; verkoperId: string; }>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.verkoper-van-bestelbon-wijzigen", "verkoper van bestelbon wijzigen"), "BestelBon", ({id}) => `/bestelbon/${id}/verkoper`),
    updateBestelBonFacturatieInfo: buildUpdateMutation<void, { id: string; facturatieInfo: string; }>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.bestelbon-facturatie-info-wijzigen", "bestelbon facturatie info wijzigen"), "BestelBon", ({id}) => `/bestelbon/${id}/facturatie-info`, {}, {method: "PATCH"}),
    updateBestelBonInstallatieInfo: buildUpdateMutation<void, { id: string; installatieInfo: string; installatieInfoTeMelden: boolean; }>(build,
        installTranslationKey("bestelbon:APIFoutmeldingen.bestelbon-facturatie-info-wijzigen", "bestelbon installatieinfo wijzigen"), "BestelBon", ({id}) => `/bestelbon/${id}/installatie-info`, {}, {method: "PATCH"}),
}));

export const useGetBestelBonById = bestelBonApi.endpoints.getBestelBonById.useQuery;
export const useSearchBestelBon = bestelBonApi.endpoints.searchBestelBon.useQuery;
export const useBestelBonAccepteerHandtekening = bestelBonApi.endpoints.bestelBonAccepteerHandtekening.useMutation;
export const useAnnuleerBestelBon = bestelBonApi.endpoints.annuleerBestelBon.useMutation;
export const useBevestigBestelBon = bestelBonApi.endpoints.bevestigBestelBon.useMutation;
export const useKoppelBestelBonAanKlant = bestelBonApi.endpoints.koppelBestelBonAanKlant.useMutation;
export const useSetFacturatieKlant = bestelBonApi.endpoints.setFacturatieKlant.useMutation;
export const useVerwijderFacturatieKlant = bestelBonApi.endpoints.verwijderFacturatieKlant.useMutation;
export const useKoppelBestelBonVerzendAdressen = bestelBonApi.endpoints.koppelBestelBonVerzendAdressen.useMutation;
export const useBestelBonLaadBewijsstukPdf = bestelBonApi.endpoints.bestelBonLaadBewijsstukPdf.useMutation;
export const useBestelBonNieuwContract = bestelBonApi.endpoints.bestelBonNieuwContract.useMutation;
export const useTekenBestelBon = bestelBonApi.endpoints.tekenBestelBon.useMutation;
export const useVerwerkBestelBon = bestelBonApi.endpoints.verwerkBestelBon.useMutation;
export const useBestelBonVerwijderContract = bestelBonApi.endpoints.bestelBonVerwijderContract.useMutation;
export const useBestelBonWeigerHandtekening = bestelBonApi.endpoints.bestelBonWeigerHandtekening.useMutation;
export const useBestelBonWijzigCommissieVerdeling = bestelBonApi.endpoints.bestelBonWijzigCommissieVerdeling.useMutation;
export const useUpdateBestelBon = bestelBonApi.endpoints.updateBestelBon.useMutation;
export const useUpdateBestelBonFacturatieInfo = bestelBonApi.endpoints.updateBestelBonFacturatieInfo.useMutation;
export const useUpdateBestelBonInstallatieInfo = bestelBonApi.endpoints.updateBestelBonInstallatieInfo.useMutation;
export const useUpdateBestelBonVerkoper = bestelBonApi.endpoints.updateBestelBonVerkoper.useMutation;
export const useUpdateBestelBonLijnInstallatieInfo = bestelBonApi.endpoints.updateBestelBonLijnInstallatinfo.useMutation;
export const useBestelBonLijnInstallatieInfoVoegSituatieFotoToe = bestelBonApi.endpoints.voegBestelBonLijnInstallatinfoSituatieFotoToe.useMutation;
export const useBestelBonLijnInstallatieInfoVerwijderSituatieFoto = bestelBonApi.endpoints.verwijderBestelBonLijnInstallatinfoSituatieFoto.useMutation;
