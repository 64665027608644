import * as React from "react";
import {Button, Spinner} from "react-bootstrap";
import {ButtonProps} from "react-bootstrap/Button";

export interface LoadingButtonProps extends ButtonProps {
    loading?: boolean;
    loaded?(): void;
}

export const LoadingButton: React.FC<LoadingButtonProps> = (props) => {
    const { loading, loaded, children, ...rest } = props;

    const onClick = (e) => {
        if (props.onClick) {
            const result = props.onClick(e);

            if (result?.["then"]) {
                (result as Promise<any>).then(() => loaded?.());
            } else {
                loaded?.();
            }
        }
    };

    return (
        <Button disabled={loading} {...rest} onClick={onClick}>
            {loading && <Spinner animation="border" size="sm" className="mr-2"/>}

            {children}
        </Button>
    );
};
