import {default as React} from "react";
import {RootState} from "../../../../redux/reducers";
import {connect} from 'react-redux';
import {hideModal} from "../../../../redux/ui/actions";
import {Button, Form} from "react-bootstrap";
import PrestatieStatusBadge from "../../../../components/aqualex/opdracht/PrestatieStatusBadge";
import {PrestatieStatus} from "../../../../redux/planning/types";
import {Formik} from 'formik';
import * as Yup from 'yup';
import {TextAreaFormField} from "../../../../components/aqualex/form/TextAreaFormField";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../components/aqualex/Modal";
import {useTranslation} from "../../../../helpers/i18nUtils";
import {Trans} from "react-i18next";

const HerbezoekOnderhoudServiceOrderModal = ({
                                                 serviceOrderBedrijfId,
                                                 serviceOrderNr,
                                                 serviceOrderPlatformId,
                                                 onBevestigHandler,
                                                 hideModal,
                                             }) => {

    const onBevestig = ({serviceOrderBedrijfId, serviceOrderPlatformId, opmerking}) => {
        hideModal();
        onBevestigHandler(serviceOrderBedrijfId, serviceOrderPlatformId, opmerking);
    }

    const schema = Yup.object({
        serviceOrderBedrijfId: Yup.string().required(),
        serviceOrderPlatformId: Yup.string().required(),
        opmerking: Yup.string()
    });

    const {t} = useTranslation("planning");

    return (
        <Modal show={true} onHide={hideModal} autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("HerbezoekOnderhoudServiceOrderModal.herbezoek-serviceorder", "Herbezoek serviceorder")}</ModalTitle>
            </ModalHeader>

            <Formik validationSchema={schema}
                    onSubmit={onBevestig}
                    initialValues={{
                        serviceOrderBedrijfId: serviceOrderBedrijfId,
                        serviceOrderPlatformId: serviceOrderPlatformId,
                        opmerking: "",
                    }}>
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      isValid,
                      errors,
                      isSubmitting
                  }) => (
                    <Form noValidate>

                        <ModalBody>

                            <Trans t={t}
                                   i18nKey="HerbezoekOnderhoudServiceOrderModal.omschrijving">
                                <p>Deze functie plant een uitgevoerd serviceorder (met
                                    herstelstatuscode <strong>GEPLAND</strong>) voor een tweede keer.</p>
                            </Trans>
                            <div className="mb-3">
                                <TextAreaFormField name="opmerking"
                                                   label={t("Labels.opmerking", "Opmerking")}
                                                   rows={3}
                                                   maxLength={200}
                                                   placeholder=""/>
                            </div>

                            <Trans t={t}
                                   i18nKey="HerbezoekOnderhoudServiceOrderModal.bijkomende-informatie">
                                <p>Merk op dat alle opdrachten gelinkt aan
                                    serviceorder <strong>{{serviceOrderNr}}</strong> worden geïmpacteerd
                                    (de bestaande prestatie wordt op <PrestatieStatusBadge
                                        status={PrestatieStatus.ONVOLTOOID}/> gezet en er
                                    wordt een nieuwe <PrestatieStatusBadge
                                        status={PrestatieStatus.UIT_TE_VOEREN}/> prestatie aangemaakt). Ook het
                                    serivceorder
                                    wordt terug op <strong>AUTO_PLAN</strong> gezet.
                                </p>
                            </Trans>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='light' onClick={hideModal}>
                                {t("algemeen:Buttons.annuleer", "Annuleer")}
                            </Button>{' '}
                            <Button variant='primary' disabled={!isValid}
                                    onClick={() => handleSubmit()}>
                                {t("algemeen:Buttons.bevestigen", "Bevestigen")}</Button>
                        </ModalFooter>
                    </Form>)}

            </Formik>


        </Modal>
    );
};

const mapStateToProps = (state: RootState, ownProps) => ({
    onBevestigHandler: ownProps.onBevestigHandler,
    serviceOrderNr: ownProps.serviceOrderNr,
});

const mapDispatchToProps = {
    hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(HerbezoekOnderhoudServiceOrderModal);
