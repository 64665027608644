export const blobToDataUri = (blob: Blob) => {
    return new Promise<string>((resolve) => {
        const fileReader = new FileReader();

        fileReader.onload = (event) => {
            if (event.target) {
                resolve(event.target.result as string);
            }
        };

        fileReader.readAsDataURL(blob);
    });
};
