import {default as React} from "react";
import {RootState} from "../../../redux/reducers";
import {connect} from 'react-redux';
import {hideModal} from "../../../redux/ui/actions";
import moment from 'moment';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {Button} from "react-bootstrap";
import {useTranslation} from "../../../helpers/i18nUtils";
import {Form, Formik} from "formik";
import {DatumFormField} from "../../../components/aqualex/form/DatumFormField";
import {LoadingButton} from "../../../components/aqualex/LoadingButton";
import * as Yup from "yup";

export interface StelInstallatieUitFormValues {
    uitgesteldTotDatum?: Date;
}

export interface InstallatieStelUitModalProps {
    onStelUitHandler: Function,
    hideModal: Function
}

const InstallatieStelUitModal: React.FC<InstallatieStelUitModalProps> = (props) => {
    const {onStelUitHandler, hideModal} = props;

    const standaardDatum = moment().add(7, "days").toDate();

    const onAnnuleer = () => {
        hideModal();
    };

    const onBevestig = (values: StelInstallatieUitFormValues) => {
        hideModal();
        onStelUitHandler(values.uitgesteldTotDatum);
    };

    const {t} = useTranslation("installatie");

    const validationSchema = Yup.object().shape({
        uitgesteldTotDatum: Yup.date().nullable().required(t("Foutmeldingen.datum-is-verplicht", "Datum is verplicht") as string),
    });

    return (
        <Modal show onHide={onAnnuleer} autoFocus>
            <Formik<StelInstallatieUitFormValues> initialValues={{uitgesteldTotDatum: standaardDatum}}
                                                  validationSchema={validationSchema}
                                                  onSubmit={onBevestig}>
                {
                    (formikProps) => {
                        return (
                            <Form>
                                <ModalHeader closeButton={true}>
                                    <ModalTitle>{t("Titels.installatie-uitstellen", "Installatie uitstellen")}</ModalTitle>
                                </ModalHeader>
                                <ModalBody>
                                    <p>{t("InstallatieStelUitModal.tot-wanneer-wil-je-uitstellen", "Tot wanneer wil je uitstellen?")}</p>

                                    <DatumFormField name="uitgesteldTotDatum" minDate={new Date()}/>
                                </ModalBody>
                                <ModalFooter>
                                    <Button variant="light" onClick={onAnnuleer}>{
                                        t("algemeen:Buttons.annuleer", "Annuleer")}
                                    </Button>
                                    <LoadingButton variant="primary" type="submit" disabled={formikProps.isSubmitting}
                                                   loading={formikProps.isSubmitting}>
                                        {t("algemeen:Buttons.bevestig", "Bevestig")}
                                    </LoadingButton>
                                </ModalFooter>
                            </Form>
                        );
                    }
                }
            </Formik>
        </Modal>
    );
};

const mapStateToProps = (state: RootState, ownProps) => ({
    onStelUitHandler: ownProps.onStelUitHandler,
});

const mapDispatchToProps = {
    hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(InstallatieStelUitModal);
