import {ArtikelFilters, ArtikelModel} from "./types";
import {Page} from "../support/pagination/types";
import {FiltersAndPageable} from "../support/uiSlice";
import {buildApi, buildGetByIdQuery, buildQuery} from "../support/ApiBuilderUtils";
import {Co2ArtikelModel} from "../co2bestelling/types";
import {installTranslationKey} from "../../helpers/i18nUtils";

export const artikelApi = buildApi(build => ({
    searchArtikelen: build.query<Page<ArtikelModel>, FiltersAndPageable<ArtikelFilters>>({
        query: ({filters, pageable}) => ({
            url: "/artikel/search",
            params: {
                ...filters,
                page: pageable.pageNumber - 1,
                size: pageable.pageSize,
                sort: `${pageable.sortField},${pageable.sortOrder}`,
            },
        }),
        transformResponse: (response: Page<ArtikelModel>) => {
            return {
                content: response.content,
                number: response.number + 1,
                size: response.size,
                totalElements: response.totalElements,
                loading: false,
            };
        },
        providesTags: (result, error, page) =>
            result
                ? [
                    // Provides a tag for each post in the current page,
                    // as well as the 'PARTIAL-LIST' tag.
                    ...result.content.map(({id}) => ({type: 'Artikel' as const, id})),
                    {type: 'Artikel', id: 'PARTIAL-LIST'},
                ]
                : [{type: 'Artikel', id: 'PARTIAL-LIST'}],
    }),
    getArtikelById: buildGetByIdQuery<ArtikelModel, string>(build,
        installTranslationKey("artikel:APIFoutmeldingen.ophalen-van-artikel", "ophalen artikel"), "Artikel", id => `/artikel/${id}`),
    getCo2Artikelen: buildQuery<Co2ArtikelModel[], {klantId?: string; verzendAdresId?: string}>(build,
        installTranslationKey("artikel:APIFOutmeldingen.ophalen-van-co2-artikelen", "ophalen van CO2-artikelen"), "Artikel", () => "/artikel/co2")
}));

export const useSearchArtikelen = artikelApi.endpoints.searchArtikelen.useQuery;
export const useGetArtikelById = artikelApi.endpoints.getArtikelById.useQuery;
export const useGetArtikelByIdLazy = artikelApi.endpoints.getArtikelById.useLazyQuery;
export const useGetCo2Artikelen = artikelApi.endpoints.getCo2Artikelen.useQuery;
