import React, {useCallback, useMemo} from "react";
import {Alert, Col, Form, FormGroup, Modal, Row} from "react-bootstrap";
import {BedrijfFormField} from "../../../../components/aqualex/form/BedrijfFormField";
import {SimpleFormField} from "../../../../components/aqualex/form/SimpleFormField";
import {MedewerkerFormField} from "../../../../components/aqualex/form/MedewerkerFormField";
import {AdresAutocompleteInput} from "../../../../components/aqualex/form/AdresAutocompleteInput";
import {SelectFormField} from "../../../../components/aqualex/form/SelectFormField";
import {KlantLanden, KlantModel, KlantTalen} from "../../../../redux/klant/types";
import {CreateKlantModalSimilarKlanten} from "./CreateKlantModalSimilarKlanten";
import {Page} from "../../../../redux/support/pagination/types";
import {AdresModel} from "../../../../redux/verzendadres/types";
import {useFormikContext} from "formik";
import {NieuweKlantModalFormValues} from "../CreateKlantModal";
import {useTranslation} from "../../../../helpers/i18nUtils";
import {Trans} from "react-i18next";

export interface CreateKlantModalAlgemeneInformatieProps {
    similarKlantNaam: Page<KlantModel>;
    similarKlantEmailadres: Page<KlantModel>;
    similarKlantBtwNummer: Page<KlantModel>;
}

export const CreateKlantModalAlgemeneInformatie: React.FC<CreateKlantModalAlgemeneInformatieProps> = (props) => {
    const {similarKlantNaam, similarKlantEmailadres, similarKlantBtwNummer} = props;

    const {setFieldValue, values} = useFormikContext<NieuweKlantModalFormValues>();

    const onAdresAutocomplete = useCallback((adres: Partial<AdresModel>) => {
        setFieldValue("algemeneInformatie:adres", adres.adres);
        setFieldValue("algemeneInformatie:adres2", undefined);
        setFieldValue("algemeneInformatie:postcode", adres.postcode);
        setFieldValue("algemeneInformatie:plaats", adres.plaats);
        setFieldValue("algemeneInformatie:landCode", adres.landCode);
    }, [setFieldValue]);

    const showHuisnummerWaarschuwing = useMemo(() => {
        return values["algemeneInformatie:adres"] && !/\d+/.test(values["algemeneInformatie:adres"]);
    }, [values]);

    const {t} = useTranslation("klant");

    return (
        <Modal.Body>
            <CreateKlantModalSimilarKlanten similarKlantNaam={similarKlantNaam}
                                            similarKlantBtwNummer={similarKlantBtwNummer}
                                            similarKlantEmailadres={similarKlantEmailadres}/>

            <Form.Row>
                <FormGroup id="bedrijfId" as={Col} sm={6} xs={12}>
                    <BedrijfFormField name="algemeneInformatie:bedrijfId"
                                      label={t("Labels.bedrijf", "Bedrijf")}
                                      isClearable={false}/>
                </FormGroup>

                <FormGroup id="naam" as={Col} sm={6} xs={12}>
                    <SimpleFormField capitalize name="algemeneInformatie:naam"
                                     label={t("Labels.naam", "Naam")}
                                     placeholder={t("Labels.naam", "Naam")}/>
                </FormGroup>

                <FormGroup id="verkoper" as={Col} sm={6} xs={12}>
                    <MedewerkerFormField name="algemeneInformatie:verkoperId"
                                         label={t("Labels.verkoper", "Verkoper")}
                                         isClearable={false} useId/>
                </FormGroup>

                <FormGroup id="btwNummer" as={Col} sm={6} xs={12}>
                    <SimpleFormField name="algemeneInformatie:btwNummer"
                                     label={t("Labels.btw-nummer", "Btw-nummer")}
                                     placeholder={t("Placeholders.btw-nummer", "Btw-nummer")}/>
                </FormGroup>
            </Form.Row>

            <hr/>

            <Form.Row>
                <Col xs={12}>
                    <div className="mb-2 d-flex align-items-center justify-content-start">
                        <i className="mdi mdi-map-marker-outline font-24 mr-2"/>
                        <div className="flex-grow-1">
                            <AdresAutocompleteInput name="adres-autocomplete"
                                                    onChange={onAdresAutocomplete}/>
                            <div className="text-muted p-1">
                                <Trans t={t}
                                    i18nKey="CreateKlantModalAlgemeneInformatie.zoek-hier-een-adres-om-velden-automatisch-in-tevullen">
                                Zoek hier een adres op om de onderstaande velden automatisch in te vullen.
                                </Trans>
                            </div>
                        </div>
                    </div>

                    <Form.Row>
                        <Col as={Col} md={6} sm={12}>
                            <Row>
                                <FormGroup as={Col} xs={12}>
                                    <SimpleFormField name="algemeneInformatie:adres"
                                                     label={t("Labels.adresregel", "Adresregel")}
                                                     placeholder={t("Placeholders.adresregel","Adresregel")}/>
                                    <SimpleFormField name="algemeneInformatie:adres2"
                                                     placeholder={t("Labels.tweede-adresregel", "Tweede adresregel")}/>

                                    {showHuisnummerWaarschuwing && (
                                        <Alert variant="warning" className="p-1">
                                            <Trans t={t}
                                                i18nKey="Foutmeldingen.we-vinden-geen-huisnummer-terug">
                                                We vinden geen huisnummer terug. Gelieve dit na te kijken.
                                            </Trans>
                                        </Alert>
                                    )}
                                </FormGroup>
                            </Row>
                        </Col>

                        <FormGroup as={Col} md={6} sm={12}>
                            <Row>
                                <Col sm={4} className="pr-1">
                                    <SimpleFormField name="algemeneInformatie:postcode"
                                                     label={t("Labels.postcode", "Postcode")}
                                                     placeholder="1000"/>
                                </Col>
                                <Col sm={8} className="pl-0">
                                    <SimpleFormField name="algemeneInformatie:plaats"
                                                     label={t("Labels.plaats", "Plaats")}
                                                     placeholder={t("Labels.plaats", "Plaats")} uppercased
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} className="pr-1">
                                    <SelectFormField name="algemeneInformatie:landCode">
                                        <option value="">
                                            {t("Placeholders.selecteer-een-land", "Selecteer een land...")}
                                        </option>
                                        {Object.entries(KlantLanden).map(([landCode, label]) => (
                                            <option key={landCode} value={landCode}>{label}</option>
                                        ))}
                                    </SelectFormField>
                                </Col>
                                <Col sm={6} className="pl-0">
                                    <SelectFormField name="algemeneInformatie:taal">
                                        {Object.entries(KlantTalen).map(([locale, label]) => (
                                            <option key={locale} value={locale}>{label}</option>
                                        ))}
                                    </SelectFormField>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form.Row>
                </Col>

            </Form.Row>
        </Modal.Body>
    );
};
