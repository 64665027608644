import {default as React} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {useDispatch} from 'react-redux';

import {Formik,} from 'formik';
import {hideModal} from "../../../redux/ui/actions";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {useTranslation} from "react-i18next";
import {BestelBonModel} from "../../../redux/bestelbon/types";

export interface BevestigBestelbonModalProps {
    bestelbon: BestelBonModel;
    onBevestigHandler: (values: any) => void;
}

export const BevestigBestelbonModal: React.FC<BevestigBestelbonModalProps> = (props) => {
    const {bestelbon, onBevestigHandler} = props;

    const dispatch = useDispatch();

    const onBevestig = (values) => {
        dispatch(hideModal());
        onBevestigHandler({
            installatieInfoTeMelden: !values.geenInstallatieInfoTeMelden,
            installatieInfo: values.installatieInfo
        });
    }

    const {t} = useTranslation("bestelbon");

    return (
        <Modal show={true} onHide={hideModal} size='lg' autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Titels.bestelbon-bevestigen",
                    "Bestelbon bevestigen")}</ModalTitle>
            </ModalHeader>

            <Formik onSubmit={onBevestig}
                    validateOnBlur={false}
                    validateOnChange={false}
                    initialValues={{
                        geenInstallatieInfoTeMelden: false,
                        installatieInfo: bestelbon.installatieInfo?.installatieInfo || "",
                    }}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting
                  }) => (
                    <Form noValidate>
                        <ModalBody>
                            <Alert variant='warning'>{t("BevestigBestelBonModal.deze-actie-is-definitief",
                                "Deze actie is definitief.")}</Alert>

                            {/*{!installatieInfoGevalideerdDoorGebruiker && (*/}
                            {/*    <>*/}
                            {/*        <p>{t("BevestigBestelBonModal.de-technische-voorzieningen-werden-nog-niet-doorgegeven", "De technische voorzieningen werden nog niet doorgegeven.")}</p>*/}

                            {/*        <Form.Group>*/}
                            {/*            <Form.Check type="radio"*/}
                            {/*                        label={"Alles is aanwezig bij de klant voor een installatie"}/>*/}
                            {/*            <Form.Check type="radio"*/}
                            {/*                        label={"Niet alle voorzieningen zijn nog in orde voor een installatie"}/>*/}
                            {/*            <Form.Check type="radio"*/}
                            {/*                        label={"Ik wens dit specifiek per toestel in te geven"}/>*/}
                            {/*        </Form.Group>*/}
                            {/*    </>*/}
                            {/*)}*/}

                        </ModalBody>

                        <ModalFooter>
                            <Button variant='light'
                                    onClick={() => dispatch(hideModal())}>{t("algemeen:Buttons.annuleer",
                                "Annuleer")}</Button>{' '}
                            <Button variant='primary' disabled={!isValid}
                                    onClick={() => handleSubmit()}>{t("algemeen:Buttons.bevestigen", "Bevestigen")}</Button>
                        </ModalFooter>
                    </Form>)}
            </Formik>

        </Modal>
    );
};

