import { KlantModel } from '../klant/types';
import { VerzendAdresModel } from '../verzendadres/types';
import { MedewerkerModel } from '../medewerker/types';
import { OperationeelToestelModel } from '../toestel/types';
import { OpdrachtPlanningModel, Skill } from '../planning/types';
import { _Links, TransportKostConfiguratieModel, TransportKostConfiguratieType } from '../types';
import { BestandModel } from '../installatie/types';
import moment from 'moment/moment';
import { ArtikelMetAantalModel } from '../artikel/types';

export enum InterventieStatus {
    IN_ONTWERP = 'IN_ONTWERP',
    GEANNULEERD = 'GEANNULEERD',
    WACHT_OP_KLANT_AKKOORD = 'WACHT_OP_KLANT_AKKOORD',
    KLANT_AKKOORD = 'KLANT_AKKOORD',
    KLANT_NIET_AKKOORD = 'KLANT_NIET_AKKOORD',
    PLANNEN = 'PLANNEN',
    UITGEVOERD = 'UITGEVOERD',
    WACHT_OP_UITVOERING = 'WACHT_OP_UITVOERING',
    NIET_UITGEVOERD = 'NIET_UITGEVOERD',
    UITGESTELD = 'UITGESTELD'
}

export interface InterventieFilters {
    nummer?: number;
    status?: InterventieStatus[];
    statusNotIn?: InterventieStatus[];
    prioriteit?: InterventiePrioriteit[];
    serviceArtikelNr?: string;
    klantNr?: string;
    klantNaam?: string;
    klantDivisie?: string;
    multiToestelFirstOrNull?: boolean;

    isGepland?: boolean;
    geplandeDatum?: Date;
    deadlineTot?: Date;
    skillIn?: string[];
}

export enum InterventiePrioriteit {
    SLA = "SLA",
    LEK = "LEK",
    NORMAAL = "NORMAAL",
    LAAG = "LAAG"
}

export enum InterventieTaakType {
    HERSTELLING = "HERSTELLING",
    UPGRADE = "UPGRADE",
    VERPLAATSING = "VERPLAATSING",
    VERHUIS = "VERHUIS",
    OPHALING = "OPHALING",
    STAALNAME_LABO_ONDERZOEK = "STAALNAME_LABO_ONDERZOEK",
    ADVIES = "ADVIES",
    OPSTART = "OPSTART"
}

export interface InterventieOverzichtModel {
    id: string;
    nummer: string;
    toestel: OperationeelToestelModel;

    klant: KlantModel;
    verzendAdres: VerzendAdresModel;
    status: InterventieStatus;

    creatieTijdstip: string;

    aangemaaktDoor: MedewerkerModel;
    medewerkers: MedewerkerModel[];

    nodigeSkills: string[];
    verwachteDuurtijd: number;
    ticket: string;
    deadline: string;
    prioriteit: InterventiePrioriteit;

    taakType: InterventieTaakType;
    taakOmschrijving: string;

    opdrachtId?: string;
}

export enum ControleWerkingResultaat {
    OKE = "OKE",
    OKE_OPVOLGING_VEREIST = "OKE_OPVOLGING_VEREIST",
    DEFECT = "DEFECT"
}

export interface InterventieModel {
    id: string;
    nummer: string;
    rawNummer: number;
    volgNummer?: number;
    operationeelToestel: OperationeelToestelModel;

    klant: KlantModel;
    verzendAdres: VerzendAdresModel;
    status: InterventieStatus;

    creatieTijdstip: string;

    aangemaaktDoor: MedewerkerModel;
    medewerkers: MedewerkerModel[];

    geannuleerdDoor?: MedewerkerModel;
    geannuleerdTijdstip?: string;
    annulatieReden?: string;

    nodigeSkills: Skill[];
    verwachteDuurtijd: number;
    ticket: string;
    deadline: string;
    prioriteit: InterventiePrioriteit;

    taakType: InterventieTaakType;
    taakOmschrijving: string;

    opdrachtId?: string;

    opdrachtPlanning?: OpdrachtPlanningModel;

    klantAkkoordVraag?: string;
    klantAkkoordGevraagdDoor?: MedewerkerModel;
    klantAkkoordGevraagdTijdstip?: string;

    klantAkkoordVerklaring?: string;
    klantAkkoordDoor?: MedewerkerModel;
    klantAkkoordTijdstip?: string;
    interventieOfferte?: BestandModel;

    uitgevoerdDoor?: MedewerkerModel[];
    aankomstTijdstip?: string;
    serviceOrder?: ServiceOrderModel;
    opmerking?: string;
    resultaat?: ControleWerkingResultaat;
    resultaatOpmerking?: string;

    emailAdressen: string[];
    magazijnInstructies?: string;
    garantieProfiel: InterventieGarantieProfiel;

    transportKostConfiguratie: TransportKostConfiguratieModel;

    meeTeGevenArtikels: ArtikelMetAantalModel[];

    bijlage?: BestandModel;
    klantReferentie?: string;

    uitgesteldTotDatum?: string;
}

export const interventieTeLaatGepland = (interventie: InterventieModel): boolean => {
    if (!interventie.opdrachtPlanning || interventie.opdrachtPlanning.entries.length === 0) {
        return false;
    }

    return moment(interventie.opdrachtPlanning.entries[0].geplandeDatum).isAfter(moment(interventie.deadline));
};

export enum InterventieGarantieProfiel {
    HUUR = "HUUR",

    KOOP_GARANTIE_24 = "KOOP_GARANTIE_24",
    KOOP_GARANTIE_EXT27 = "KOOP_GARANTIE_EXT27",
    KOOP_GARANTIE_EXT30 = "KOOP_GARANTIE_EXT30",
    KOOP_GARANTIE_EXT36 = "KOOP_GARANTIE_EXT36",
    KOOP_BUITEN_GARANTIE = "KOOP_BUITEN_GARANTIE",

    GEEN_GARANTIEGEVAL = "GEEN_GARANTIEGEVAL",
    SPECIALE_BEHANDELING_BETALEND = "SPECIALE_BEHANDELING_BETALEND",
    SPECIALE_BEHANDELING_GRATIS = "SPECIALE_BEHANDELING_GRATIS"

}

export interface ServiceOrderModel {
    bedrijfId: string;
    id: string;
    nr: string;
    soort: string;
    _links: _Links;
}

export interface ArtikelMetAantalForm {
    artikelId: string;
    aantal: number;
}

export interface NieuweInterventieForm {
    klantId: string;
    verzendAdresId: string;
    operationeelToestelIds: string[];
    taakType: InterventieTaakType;
    taakOmschrijving: string;
    prioriteit: InterventiePrioriteit;
    meeTeGevenArtikels: ArtikelMetAantalForm[];
    ticket?: string;
    verwachteDuurtijd: number;
    nodigeSkills: Skill[];
    emailAdressen: string[];
    magazijnInstructies: string;
    transportKostType: TransportKostConfiguratieType;
    transportKostValue?: number;
}

export interface BereidInterventiePlanningVoorForm {
    deadline: string;
}

export interface UpdateInterventieGarantieProfielForm {
    garantieProfiel: InterventieGarantieProfiel;
}

export interface UpdateInterventieForm {
    klantReferentie?: string;
    taakOmschrijving?: string;
    garantieProfiel?: InterventieGarantieProfiel;
    meeTeGevenArtikels?: ArtikelMetAantalForm[];
    emailAdressen?: string[];
    magazijnInstructies?: string;
    nodigeSkills?: Skill[];
}
