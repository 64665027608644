import React from "react";
import {Badge} from "react-bootstrap";

export const DivisieBadge: React.FC<{ divisie: string }> = ({divisie}) => {
    return (
        <Badge variant="warning">
            {divisie}
        </Badge>
    );
};
