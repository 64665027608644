import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {MsalProvider, useAccount, useMsal, useMsalAuthentication} from "@azure/msal-react";
import Config from "../helpers/Config";

import {InteractionType} from "@azure/msal-browser";
import {authClient} from "./client";
import {useLoggedInUser, useLoginUser} from "../redux/auth/hooks";
import LoginApi from "../helpers/LoginApi";
import LoaderWidget from "../components/Loader";
import {SilentRequest} from "@azure/msal-browser/dist/request/SilentRequest";
import {useTranslation} from "../helpers/i18nUtils";

export interface AuthProviderProps {
    unauthenticatedChildren?: ReactNode;
}

const InnerAuthProvider: React.FC<AuthProviderProps> = (props) => {
    useMsalAuthentication(InteractionType.Redirect, {
        scopes: Config.OAUTH_CLIENT_SCOPES
    });

    const {changeLanguage} = useTranslation();
    const [loaded, setLoaded] = useState(false);

    const {instance} = useMsal();
    const account = useAccount(instance.getActiveAccount() || {});

    const user = useLoggedInUser();
    const loginUser = useLoginUser();

    useEffect(() => {
        async function run() {
            if (!account) {
                return;
            }

            const tokenResult = await authAcquireTokenOrRedirect();

            // TODO: naar saga verhuizen?
            const me = await LoginApi.me();

            if (tokenResult) {
                loginUser({
                    info: account,
                    me,
                    token: tokenResult
                });

                setLoaded(true);
            }
        }

        if (account && !user) {
            run();
        }

        if (account && user) {
            setLoaded(true);
        }
    }, [account, instance, user, loginUser]);

    useEffect(() => {
        if (user?.taal) {
            changeLanguage(user.taal);
        }
    }, [user, changeLanguage]);

    if (!loaded) {
        return <>{props.unauthenticatedChildren || <div className="vh-100"><LoaderWidget/></div>}</>;
    }

    return (
        <>{props.children}</>
    );
};

export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        authClient.initialize().then(() => {
            if (!authClient.getActiveAccount()) {
                const accounts = authClient.getAllAccounts();
                const accountEnvironment = new URL(Config.OAUTH_AUTHORITY).hostname;
                const account = accounts.find(account => account.environment === accountEnvironment);
                if (account) {
                    authClient.setActiveAccount(account);
                }
            }

            setInitialized(true);
        }).catch(error => console.error(error));
    }, []);

    if (!initialized) {
        return null;
    }

    return (
        <MsalProvider instance={authClient}>
            <InnerAuthProvider unauthenticatedChildren={props.unauthenticatedChildren}>
                {props.children}
            </InnerAuthProvider>
        </MsalProvider>
    );
};

export const useLogout = () => {
    const {instance} = useMsal();
    const account = useAccount(instance.getActiveAccount() || {});

    return useCallback(() => {
        instance.logout({
            account,
            extraQueryParameters: {id_token_hint: account?.idToken || ""}
        });
    }, [instance, account]);
};

const acquireTokenRequestDefault: SilentRequest = {
    scopes: Config.OAUTH_CLIENT_SCOPES
};

export const authAcquireTokenSilent = async (): Promise<any> => {
    const account = authClient.getActiveAccount();

    if (account) {
        try {
            const response = await authClient.acquireTokenSilent({
                ...acquireTokenRequestDefault,
                account: account
            });

            return response;
        } catch (e) {
            console.log("silent token acquisition fails.");

            authClient.acquireTokenRedirect({...acquireTokenRequestDefault, account}).catch(console.error);
        }
    }

    return null;
};

export const authAcquireTokenRedirect = async () => {
    const account = authClient.getActiveAccount();

    if (account) {
        await authClient.acquireTokenRedirect({
            ...acquireTokenRequestDefault,
            account: account
        });
    }

    return null;
};

export const authAcquireTokenOrRedirect = async (): Promise<any> => {
    try {
        const result = await authAcquireTokenSilent();

        return result;
    } catch (error: any) {
        console.error(error);
    }
};
