import React, {useEffect, ImgHTMLAttributes} from "react";
import {authenticatedFetch} from "../../helpers/api";
import {blobToDataUri} from "../../helpers/DownloadUtils";

export interface AuthenticatedImageProps extends ImgHTMLAttributes<any> {
    src: string;
}

export const AuthenticatedImage: React.FC<AuthenticatedImageProps> = (props) => {
    const {src, ...rest} = props;

    const [base64Src, setBase64Src] = React.useState<string | null>(null);

    useEffect(() => {
        authenticatedFetch(src, "ophalen afbeelding")
            .then(response => response.blob())
            .then(blob => blobToDataUri(blob))
            .then(setBase64Src);
    }, [src]);

    if (!base64Src) {
        return null;
    }

    return (
        <img alt="" {...rest} src={base64Src}/>
    );
};
