import {BestandModel} from "../installatie/types";
import {MedewerkerModel} from "../medewerker/types";
import {_Links} from "../types";
import {ServiceBezoekOpvolgingRefModel, TechnicusVanTotTijdstip} from "../opvolging/types";

export enum ServiceArtikelStatus {
    BLANCO = "BLANCO",
    INSTALLED = "INSTALLED",
    OWN_SERVICE_ITEM = "OWN_SERVICE_ITEM",
    TEMPORARILY_INSTALLED = "TEMPORARILY_INSTALLED",
    VIA_DERDEN = "VIA_DERDEN",
    DEFECT = "DEFECT",
    DUBBEL_FOUT = "DUBBEL_FOUT",
}

export enum Oorsprong {
    AQUALEX = "AQUALEX",
    NON_AQUALEX = "NON_AQUALEX",
}

export enum ToestelTechnischeOorsprong {
    DYNAMICS = "DYNAMICS",
    INSTALLATIE = "INSTALLATIE",
    BACKOFFICE_MANUEEL = "BACKOFFICE_MANUEEL",
}

export enum TransactieType {
    HUREN = "HUREN",
    KOPEN = "KOPEN",
    NIET_ZELF_VERKOCHT = "NIET_ZELF_VERKOCHT",
}

export interface LocatieModel {
    beschrijving?: string;
    gebouw?: string;
    verdieping?: string;
    lokaalNummer?: string;
}

export interface ServiceArtikelModel {
    status: ServiceArtikelStatus;
    serieNummer?: string;
    artikel: ArtikelModel;

    _links: _Links;
}

export interface ToestelFilters {
    klantId?: string;
    verzendAdresId?: string;
    filter?: string;
    toonUitDienst?: boolean;
    serviceArtikelNr?: string;
    serieNummer?: string;
}

export enum OperationeelToestelStatus {
    OPERATIONEEL = "OPERATIONEEL",
    UIT_DIENST = "UIT_DIENST",
};

export enum MyAqualexZichtbaarheid {
    ZICHTBAAR = "ZICHTBAAR",
    VERBORGEN = "VERBORGEN",
    NIET_BESCHIKBAAR = "NIET_BESCHIKBAAR",
}

export interface MyAqualexConfig {
    zichtbaarheid: MyAqualexZichtbaarheid;
}

export interface OperationeelToestelModel {
    id: string;
    serviceArtikel?: ServiceArtikelModel;
    status: OperationeelToestelStatus;
    oorsprong: Oorsprong;
    technischeOorsprong: ToestelTechnischeOorsprong;
    installatieToestel: string;
    bedrijfId: string;
    nr: string;
    omschrijving?: string;
    artikel?: ArtikelModel;
    transactieType: TransactieType;
    klantId: string;
    verzendAdresId: string;
    locatie: LocatieModel;
    installatieDatum: string;
    uitDienstTijdstip?: string;

    klantInformatie?: OperationeelToestelKlantInformatieModel;

    bijlagen: OperationeelToestelBijlageModel[];

    onderdelen: OperationeelToestelOnderdeelModel[];

    serviceLogEntries: OperationeelToestelServiceLogEntry[];

    myAqualexConfig: MyAqualexConfig;

    onderhoudAantalPerJaar?: number;
}


export interface OperationeelToestelOnderdeelModel {
    artikel: ArtikelModel;
    serieNummer?: string;
    installatieDatum: Date;
}

export interface OperationeelToestelBijlageModel {
    id: string;
    bestand: BestandModel;
    omschrijving?: string;
    aangemaaktDoor: MedewerkerModel;
    creatieTijdstip: string;
}

export interface OperationeelToestelServiceLogEntry {
    type: string;
    medewerker?: MedewerkerModel;
    tijdstip: string;
    opmerking?: string;
    oorsprong: string;
}

export interface ServiceOrderReferenceModel {
    serviceOrderBedrijfId: string;
    serviceOrderNr: string;
    serviceOrderSoort: string;
    // test
}

export interface ToestelOnderhoudServiceLogEntry extends OperationeelToestelServiceLogEntry {
    serviceOrderReferences: ServiceOrderReferenceModel[];

    onderhoudSoort: string;

    reinigenEnSpoelen: boolean;
    uvVervangen: boolean;
    co2Vervangen: boolean;
    filtersVervangen: {
        artikel: ArtikelModel;
        lijnNr: number;
        nieuweFilterArtikel: ArtikelModel;
    }[];
    resultaat?: string;
    resultaatOpmerking?: string;
}

export interface ToestelInstallatieServiceLogEntry extends OperationeelToestelServiceLogEntry {
    serviceBezoekOpvolging: ServiceBezoekOpvolgingRefModel;
    aanwezigheden: TechnicusVanTotTijdstip[];
}

export interface ToestelInterventieServiceLogEntry extends OperationeelToestelServiceLogEntry {
    omschrijving: string;
    serviceOrderReference: ServiceOrderReferenceModel;
    resultaat?: string;
    resultaatOpmerking?: string;
}

export interface WatermeterStandOpnameLogEntry extends OperationeelToestelServiceLogEntry {
    meterstand;
}

export interface InGebruikLogEntry extends OperationeelToestelServiceLogEntry {
    omschrijving;
}

export interface UitDienstLogEntry extends OperationeelToestelServiceLogEntry {
    omschrijving;
}

export interface OpmerkingLogEntry extends OperationeelToestelServiceLogEntry {
    //no additional fields
}

export interface OperationeelToestelKlantInformatieModel {
    referentie?: string;
    locatieBeschrijving?: string;
    gebouw?: string;
    verdieping?: string;
    lokaalNummer?: string;
}

export type ToestelPage = {
    content: Array<OperationeelToestelModel>,
    //TODO: many missing properties
}

export interface Co2Kenmerken {
    standaardAantal: number;
    hervulbaar: boolean;
    maximumBestelbaar: number;
    inhoud: number;
    waarborgArtikelNr: string;
    gebruiksrechtArtikelNr: string;
}

export interface ArtikelModel {
    id: string;
    bedrijfId: string;
    nr: string;
    omschrijving: string;
    co2Kenmerken?: Co2Kenmerken;
    imageUri: string;
    thumbnailUri: string;
}

export interface ToestelKeuringAnalyseVerslagModel {
    id: string;
    toestelId: string;
    volgnummer: string;
    staalnameDatum: string;
    staalConform: boolean;
    toestelInWerking: boolean;
    staalnameUitgevoerd: boolean;
    dateCreated: string;
    bestand: BestandModel;
}


export interface UpdateMyAqualexConfigForm {
    zichtbaarheid: MyAqualexZichtbaarheid;
}

export interface UpdateLocatieForm {
    beschrijving?: string;
    gebouw?: string;
    verdieping?: string;
    lokaalNummer?: string;
}
