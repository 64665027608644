import {
    AfvoerMetSifonAanwezig,
    BestandModel,
    ProductType,
    VerkoopOrderRefModel
} from "../installatie/types";
import {AdresModel} from "../verzendadres/types";
import {MedewerkerModel} from "../medewerker/types";
import {KlantModel} from "../klant/types";
import {PartnerModel, PartnerSelectie} from "../partner/types";
import {Skill} from "../planning/types";

export type BestelBonFilters = {
    nummer?: number;
    status?: string[];
    verkoperId?: string;
    aanKlantGekoppeld?: boolean;
    offerteId?: string;
    offerteClusterId?: string;
    klantNr?: string;
    klantNaam?: string;
    klantDivisie?: string;
};

export type BestelBonPage = {
    content: Array<BestelBonModel>,
    number: number,
    size: number,
    totalElements: number,
    loading: boolean,
};

export enum BestelBonStatus {
    IN_ONTWERP = 'IN_ONTWERP',
    GEANNULEERD = 'GEANNULEERD',
    BEVESTIGD = 'BEVESTIGD',
    GETEKEND = 'GETEKEND',
    VERWERKT = 'VERWERKT',
    GEVALIDEERD_GETEKEND = 'GEVALIDEERD_GETEKEND',
}

export enum BestelbonVerzendDatumType {
    ZO_SNEL_MOGELIJK = "ZO_SNEL_MOGELIJK",
    VANAF = "VANAF",
    OP_DATUM = "OP_DATUM"
}

export interface BestelBonCommissieVerdelingModel {
    medewerkerId: string;
    percentage: number;
}

export interface BestelBonModel {

    //BestelbonOverzichtReadModel
    id: string;
    status: BestelBonStatus;
    nummer: string;
    datum: Date;
    verkoperMedewerkerId?: string;
    annulatieReden?: string,
    annulatieDatum?: Date;
    annulatieToelichting?: string;
    geannuleerdDoor?: MedewerkerModel;

    verwerkingsTijdstip?: Date;
    verwerktDoor?: MedewerkerModel;

    klant?: KlantModel,
    facturatieKlant?: KlantModel,
    prospect?: ProspectModel,
    begunstigde: ProspectModel;
    opmerking?: string;
    bedrijfId: string;
    btwNummer?: string;
    verlaagdBtwTarief?: number;
    contactEmail?: string;
    contactTelefoon?: string;
    bevestigingsDatum?: Date;
    oorsprongType: string;
    oorsprongBedrijfId?: string;
    verzendDatum?: string;
    verzendDatumType?: BestelbonVerzendDatumType;
    installatieInfo?: InstallatieInfoModel;

    aantalHuurToestellen: number;
    aantalKoopToestellen: number;

    //BestelbonDetailReadModel
    lijnen: BestelBonLijnModel[];
    contracten?: Array<ContractModel>;
    bestanden?: BestandModel[];

    partnerSelectie: PartnerSelectie;
    partnerLeverancier?: PartnerModel;
    commissieVerdeling: BestelBonCommissieVerdelingModel[];

    klantReferentieNummer?: string;

    facturatieInfo?: string;
}

export type ContractModel = {
    id: string;
    omschrijving: string;
    contractVoorstel?: BestandModel;
    getekendContract?: BestandModel;
    ondertekend: boolean;
    geaccepteerdGetekend: boolean;
    ondertekenaarMedewerkerId: string;
    ondertekenaarKlantAccountId: string;
    ondertekeningTijdstip: string;
}

export type ProspectModel = {
    id: string;
    naam: string;
    adres: string;
    adres2: string;
    postcode: string;
    plaats: string;
    landRegioCode: string;
    contact: string;
    telefoonNr: string;
    gsmNr: string;
    btwNr: string;
    prospect: boolean;
    keyAccount?: boolean;
};

export type ArtikelModel = {
    nr: string;
    omschrijving: string;
};

export type CO2Model = {
    artikelId: string;
    gebruiksrechtArtikelId: string;
    aantal: number;
};

export enum TransactieType {
    VERKOOP = 'VERKOOP',
    HUUR = 'HUUR',
}

export interface BestelBonLijnModel {
    id: string;
    besteldAantal: number;
    besteldeTermijnen: number;
    item: BestelBonLijnItemModel;
    transactieType: TransactieType;
    locatie: string;
    besteldOpVerzendAdres: AdresModel;
    verzendAdresId: string;

    verkoopOrder?: VerkoopOrderRefModel;
    verkoopOrderLijnNr?: number;

    installatieInfo: Record<number, BestelBonLijnInstallatieInfoModel>;
}

export interface BestelBonLijnItemModel {
    type: "ARTIKEL" | "PRODUCT";
}

export interface BesteldArtikelItemModel extends BestelBonLijnItemModel {
    type: "ARTIKEL";
    id: string;
    artikelId: string;
    aantal: number;
    catalogusPrijs: number;
    weergaveCatalogusPrijs: number;
    weergaveKortingPercentage: number;
    weergaveKortingVastBedrag: number;
    weergaveIngebegrepen: boolean;
    verkoopPrijs: number;
}

export interface BesteldProductItemModel extends BestelBonLijnItemModel {
    type: "PRODUCT";
    id: string;
    configuratieArtikel: ConfiguratieArtikel;
    artikelOpties: BesteldArtikelItemModel[];

    co2Artikel: BesteldArtikelItemModel;
    extraCo2FlesArtikel: BesteldArtikelItemModel;
    onderhoud: BesteldArtikelItemModel;
    installatie: BesteldArtikelItemModel;
    installatieOpties: BesteldArtikelItemModel[];
}

export interface ConfiguratieArtikel {
    omschrijving: string;
    onderdelen: BesteldArtikelItemModel[];
    berekendeCatalogusPrijs: number;
    weergaveCatalogusPrijs: number;
    weergaveKortingPercentage: number;
    weergaveKortingVastBedrag: number;
    weergaveIngebegrepen: boolean;
    verkoopPrijs: number;
}

export enum KortingType {
    BEDRAG = 'BEDRAG',
    PERCENTAGE = 'PERCENTAGE',
}

export type InstallatieInfoModel = {
    installatieInfoTeMelden: boolean;
    installatieInfo: string;
    auteurMedewerkerId: string;
}

export type UpdateInstallatieInfoModel = {
    installatieInfoTeMelden: boolean;
    installatieInfo: string;
}

export interface BestelBonLijnInstallatieInfoModel {
    gevalideerdDoorGebruiker: boolean;
    locatieBeschrijving?: string;
    gebouw?: string;
    verdieping?: string;
    lokaalNummer?: string;
    elektriciteitAanwezig: boolean;
    stopcontactenAanwezig?: number;
    opmerkingBijStopcontacten?: string;
    stopcontactenBovenAanwezig?: number;
    opmerkingBijStopcontactenBoven?: string;
    ventilatieAanwezig?: boolean;
    opmerkingBijVentilatie?: string;
    waterOpDeLeidingAanwezig: boolean;
    dubbeleDienstkraanBijToestelAanwezig?: boolean;
    opmerkingBijDubbeleDienstkraanBijToestel?: string;
    schellKraanWarmEnKoudAanwezig?: boolean;
    opmerkingBijSchellKraanWarmEnKoud?: string;
    schellKraanWarmEnKoudBovenAanwezig?: boolean;
    opmerkingBijSchellKraanWarmEnKoudBoven?: string;
    afvoerMetSifonAanwezig?: AfvoerMetSifonAanwezig;
    opmerkingBijAfvoerMetSifon?: string;
    afvoerMetSifonBovenAanwezig?: AfvoerMetSifonAanwezig;
    opmerkingBijAfvoerMetSifonBoven?: string;
    boringInWerkbladDoorKlant?: boolean;
    opmerkingBijBoringInWerkbladDoorKlant?: string;
    wachtbuis40mmAanwezig?: boolean;
    opmerkingBijWachtbuis40mm?: string;
    installatieInReedsAanwezigeKeukenOfKast?: boolean;
    opmerkingBijInstallatieInReedsAanwezigeKeukenOfKast?: string;
    algemeneOpmerking?: string;
    situatieFotos: BestandModel[];
    installatieProfiel: InstallatieProfielModel;
    index: number;
}

export interface InstallatieProfielModel {
    id: string;
    code: string;
    productType: ProductType;
    skills: Skill[];
    stopcontacten: number;
    stopcontactenBoven: number;
    dubbeleDienstkraanBijToestel: boolean;
    schellKraanWarmEnKoud: boolean;
    schellKraanWarmEnKoudBoven: boolean;
    ventilatie: boolean;
    afvoerMetSifon: boolean;
    afvoerMetSifonBoven: boolean;
    boringInWerkbladNodig: boolean;
    wachtbuis40mm: boolean;
    installatieInReedsAanwezigeKeukenOfKast: boolean;
    duurtijd: number;
}

export interface UpdateBesteldProductInstallatieInfoForm {
    locatieBeschrijving?: string;
    gebouw?: string;
    verdieping?: string;
    lokaalNummer?: string;

    elektriciteitAanwezig: boolean;
    stopcontactenAanwezig?: number;
    opmerkingBijStopcontacten?: string;
    stopcontactenBovenAanwezig?: number;
    opmerkingBijStopcontactenBoven?: string;
    ventilatieAanwezig?: boolean;
    opmerkingBijVentilatie?: string;

    waterOpDeLeidingAanwezig: boolean;
    dubbeleDienstkraanBijToestelAanwezig?: boolean;
    opmerkingBijDubbeleDienstkraanBijToestel?: string;
    schellKraanWarmEnKoudAanwezig?: boolean;
    opmerkingBijSchellKraanWarmEnKoud?: string;
    schellKraanWarmEnKoudBovenAanwezig?: boolean;
    opmerkingBijSchellKraanWarmEnKoudBoven?: string;
    afvoerMetSifonAanwezig?: AfvoerMetSifonAanwezig;
    opmerkingBijAfvoerMetSifon?: string;
    afvoerMetSifonBovenAanwezig?: AfvoerMetSifonAanwezig;
    opmerkingBijAfvoerMetSifonBoven?: string;
    boringInWerkbladDoorKlant?: boolean;
    opmerkingBijBoringInWerkbladDoorKlant?: string;
    wachtbuis40mmAanwezig?: boolean;
    opmerkingBijWachtbuis40mm?: string;
    installatieInReedsAanwezigeKeukenOfKast?: boolean;
    opmerkingBijInstallatieInReedsAanwezigeKeukenOfKast?: string;
    algemeneOpmerking?: string;
    index: number;
}
