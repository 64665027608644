import {default as React} from "react";
import {KlantModel} from "../../../redux/klant/types";
import {BestelBonModel} from "../../../redux/bestelbon/types";
import {Formik} from "formik";
import * as Yup from "yup";
import {Modal, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {useTranslation} from "react-i18next";
import {BestelBonSetFacturatieKlantModalForm} from "./BestelBonSetFacturatieKlantModalForm";

export interface BestelBonSetFacturatieKlantModalProps {
    bestelBon: BestelBonModel;
    klanten: KlantModel[];

    onBevestigHandler(klantId?: string): void;

    onNieuweKlantHandler(): void;

    hideModal(): void;

    fetchKlantByNoRequest(bedrijfId: string, klantNr: string): void;
}

export enum BestelBonSetFacturatieKlantKlantType {
    EXISTING = "EXISTING",
    NEW = "NEW"
}

export interface BestelBonSetFacturatieKlantFormValues {
    klantType: BestelBonSetFacturatieKlantKlantType;
    klantNummer: string;
    klant?: KlantModel;
}

const BestelBonSetFacturatieKlantModal: React.FC<BestelBonSetFacturatieKlantModalProps> = (props) => {
    const {
        bestelBon,
        onBevestigHandler,
        onNieuweKlantHandler,
        hideModal
    } = props;

    const onAnnuleer = () => {
        hideModal();
    };

    const onBevestig = (values: BestelBonSetFacturatieKlantFormValues) => {
        hideModal();
        if (values.klantType === BestelBonSetFacturatieKlantKlantType.EXISTING) {
            onBevestigHandler(values.klant?.id);
        } else {
            onNieuweKlantHandler();
        }
    };

    const {t} = useTranslation("bestelbon");

    const klantTypeIsVerplichtLabel = t("Foutmeldingen.klanttype-is-verplicht", "Klanttype is verplicht");
    const klantIsVerplichtLabel = t("Foutmeldingen.klant-is-verplicht", "Klant is verplicht");

    return (
        <Modal show={true} onHide={onAnnuleer} size="lg">
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Titels.facturatie-klant-instellen", "Facturatieklant instellen")}</ModalTitle>
            </ModalHeader>
            <Formik<BestelBonSetFacturatieKlantFormValues>
                initialValues={{
                    klantType: BestelBonSetFacturatieKlantKlantType.EXISTING,
                    klantNummer: ""
                }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={onBevestig}
                validationSchema={Yup.object({
                    klantType: Yup.string().required(klantTypeIsVerplichtLabel),
                    klant: Yup.object().nullable().when("klantType", {
                        is: BestelBonSetFacturatieKlantKlantType.EXISTING,
                        then: Yup.object().required(klantIsVerplichtLabel),
                        otherwise: Yup.object().optional()
                    })
                })}
            >
                <BestelBonSetFacturatieKlantModalForm bestelBon={bestelBon} onAnnuleer={onAnnuleer}/>
            </Formik>
        </Modal>
    );
};

export default BestelBonSetFacturatieKlantModal;
