import React, {HTMLAttributes} from "react";
import * as RB from "react-bootstrap";
import {ModalHeaderProps} from "react-bootstrap/ModalHeader";

export const Modal: React.FC<RB.ModalProps> = (props) => {
    return (
        <RB.Modal backdrop="static" {...props}>
            {props.children}
        </RB.Modal>
    );
};

export const ModalTitle: React.FC<HTMLAttributes<"div">> = (props) => {
    return (
        <RB.Modal.Title className="m-0" {...props as any}>
            {props.children}
        </RB.Modal.Title>
    );
};

export const ModalBody: React.FC<HTMLAttributes<"div">> = (props) => {
    return (
        <RB.Modal.Body {...props as any}>
            {props.children}
        </RB.Modal.Body>
    );
};

export const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
    return (
        <RB.Modal.Header {...props as any}>
            {props.children}
        </RB.Modal.Header>
    )
};

export const ModalFooter: React.FC<HTMLAttributes<"div">> = (props) => {
    return (
        <RB.Modal.Footer {...props as any}>
            {props.children}
        </RB.Modal.Footer>
    );
};
