import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";

i18n
    .use(initReactI18next)
    // https://www.i18next.com/how-to/add-or-load-translations
    .use(resourcesToBackend((language, namespace, callback) => {
        import(`./locales/${language}/${namespace}.json`)
            .then((resources) => {
                callback(null, resources.default);
            })
            .catch((error) => {
                callback(error, null);
            })
    }))
    .init({
        fallbackLng: {
            "nl-NL": ["nl"],
            "nl-BE": ["nl"],
            "fr-FR": ["fr"],
            "fr-BE": ["fr"],
            "de-DE": ["de"],
            "default": ["nl"]
        },
        supportedLngs: ["nl", "fr", "de"],
        ns: ["accessoire-bestelling", "algemeen", "artikel", "installatie", "bestand", "bestelbon", "bezoekaanvraag", "co2-bestelling", "installatie", "interventie", "klant", "klantaccount", "medewerker", "opdracht", "opmerking", "opvolging", "planning", "prestatie", "toestel", "transportkost"],
        defaultNS: "algemeen",

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },

        react: {
            useSuspense: false
        }
    });
