import * as React from "react";
import {KlantModel} from "../../redux/klant/types";
import VerwijderdIndicator from "./VerwijderdIndicator";
import {DivisieBadge} from "./klant/DivisieBadge";

interface KlantLabelProps {
    klant: Pick<KlantModel, "bedrijfId" | "nr" | "naam" | "verwijderd" | "divisie">;

    toonVerwijderd?: boolean;
}

const KlantLabel: React.FC<KlantLabelProps> = ({klant, toonVerwijderd=true}) => (
    <>
        <strong>{klant.bedrijfId} {klant.nr}</strong> {klant.naam} {toonVerwijderd && <VerwijderdIndicator verwijderd={klant.verwijderd}/>} {klant.divisie && <DivisieBadge divisie={klant.divisie}/>}
    </>
);

export default KlantLabel;
