import {default as React, useMemo} from "react";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";

import {Formik,} from 'formik';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {useTranslation} from "react-i18next";
import {MedewerkerMetInputFormField} from "../../../components/aqualex/form/MedewerkerMetInputFormField";
import {useHideModal} from "../../../redux/ui/hooks";
import {MedewerkerModel} from "../../../redux/medewerker/types";
import {MedewerkerBox} from "../../../components/aqualex/medewerker/MedewerkerBox";
import {SimpleFormField} from "../../../components/aqualex/form/SimpleFormField";
import {BestelBonModel} from "../../../redux/bestelbon/types";
import {useGetMedewerkerById, useGetMedewerkersByIds} from "../../../redux/medewerker/api";
import {skipToken} from "@reduxjs/toolkit/query";
import {useBestelBonWijzigCommissieVerdeling} from "../../../redux/bestelbon/api";
import {Spin} from "../../../components/Spin";
import * as Yup from "yup";
import {LoadingButton} from "../../../components/aqualex/LoadingButton";

export interface BestelBonCommssieVerdelingModalProps {
    bestelBon: BestelBonModel;

}

export type MedewerkerMetPercentageFormValue = MedewerkerModel & { percentage: number };

export interface BestelBonCommissieVerdelingFormValues {
    medewerkers: MedewerkerMetPercentageFormValue[]
}

export const BestelBonCommssieVerdelingModal: React.FC<BestelBonCommssieVerdelingModalProps> = (props) => {
    const {bestelBon} = props;

    const {t} = useTranslation("bestelbon");

    const [wijzigCommissieVerdeling, {isLoading}] = useBestelBonWijzigCommissieVerdeling();

    const {
        data: verkoper
    } = useGetMedewerkerById(bestelBon.verkoperMedewerkerId || skipToken);
    const {
        data: medewerkers,
        isLoading: medewerkersLoading
    } = useGetMedewerkersByIds(bestelBon.commissieVerdeling.map(item => item.medewerkerId));

    const initialValues: BestelBonCommissieVerdelingFormValues = useMemo(() => ({
        medewerkers: bestelBon.commissieVerdeling
            .filter(item => item.medewerkerId !== bestelBon.verkoperMedewerkerId)
            .map((medewerker) => ({
                ...medewerkers?.find(item => item.id === medewerker.medewerkerId),
                percentage: medewerker.percentage
            }))
    }), [bestelBon.commissieVerdeling, bestelBon.verkoperMedewerkerId, medewerkers]);

    const hideModal = useHideModal();

    const onSubmit = (values: BestelBonCommissieVerdelingFormValues) => {
        const commissieVerdeling = values.medewerkers?.map(({percentage, ...medewerker}) => ({
            medewerkerId: medewerker.id,
            percentage
        })) ?? [];

        wijzigCommissieVerdeling({id: bestelBon.id, commissieVerdeling}).then(() => {
            hideModal();
        });
    };

    const renderPercentageInputField = (medewerker: MedewerkerModel, i: number) => {
        return (
            <Row className="align-items-center m-2">
                <Col>
                    <MedewerkerBox clickable={false} medewerker={medewerker}/>
                </Col>

                <Col xs="auto">
                    <InputGroup>
                        <SimpleFormField name={`medewerkers[${i}].percentage`} type="number" showFeedback={false}
                                         formControlProps={{min: 0, max: 100, step: 1}}/>

                        <InputGroup.Append>
                            <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>
        );
    };

    const berekenCommissie = (medewerkers: MedewerkerMetPercentageFormValue[]) => {
        return Math.max(0, 100 - medewerkers
            .map(item => item.percentage || 0)
            .reduce((a, b) => a + b, 0));
    };

    const validationSchema = useMemo(() => Yup.object({
        medewerkers: Yup.array().nullable().of(Yup.object({
            id: Yup.string().required(),
            percentage: Yup.number().min(0).max(100).required()
        }))
    }), []);

    return (
        <Modal show={true} onHide={hideModal} size="lg" autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Titels.commissie-verdeling", "Commissieverdeling")}</ModalTitle>
            </ModalHeader>

            <Formik<BestelBonCommissieVerdelingFormValues>
                onSubmit={onSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={initialValues}>
                {({
                      handleSubmit,
                      values,
                      isValid
                  }) => (
                    <Form noValidate>
                        <ModalBody>
                            <Row className="align-items-center mb-3">
                                <Col>
                                    {verkoper && <MedewerkerBox clickable={false} medewerker={verkoper}/>}
                                </Col>
                                <Col>
                                    {t("Labels.commissie-semicolon", "Commissie:")} {berekenCommissie(values.medewerkers || [])}%
                                </Col>
                            </Row>

                            <Spin spinning={medewerkersLoading}>
                                <MedewerkerMetInputFormField name="medewerkers" label={t("Labels.andere-verkopers", "Andere verkopers")}
                                                             filter={item => item.id !== bestelBon.verkoperMedewerkerId}
                                                             renderItem={renderPercentageInputField}/>
                            </Spin>
                        </ModalBody>

                        <ModalFooter>
                            <Button variant='light'
                                    onClick={hideModal}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{" "}

                            <LoadingButton variant='primary' disabled={isLoading} loading={isLoading}
                                           onClick={() => handleSubmit()}>{t("algemeen:Buttons.bevestigen", "Bevestigen")}</LoadingButton>
                        </ModalFooter>
                    </Form>)}
            </Formik>

        </Modal>
    );
};

